import { useMutation, useQuery } from '@tanstack/react-query'
import axios, { AxiosRequestConfig } from 'axios'
import { getImpersonate } from 'helpers/impersonate'
import { assign, compact, isEmpty } from 'lodash'
import urlcat from 'urlcat'

export type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'

export const getJwtToken = () => {
  return JSON.parse(localStorage.getItem('token'))
}

export const setJwtToken = (value: string) => {
  return localStorage.setItem('token', value)
}

export const clearJwtToken = () => {
  return localStorage.removeItem('token')
}

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
})

export const fetcher = async (
  method: HttpMethod,
  url: string,
  data?: any,
  axiosOptions?: AxiosRequestConfig,
  instance = axiosInstance,
) => {
  const token = getJwtToken()
  const { impersonateId } = getImpersonate()

  const res = await instance(
    assign(
      {
        method,
        url,
      },
      token
        ? {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
              'Accept-Language': localStorage.getItem('i18nextLng') || 'fr-FR',
              Impersonate: impersonateId,
            },
          }
        : {
            headers: {
              'Accept-Language': localStorage.getItem('i18nextLng') || 'fr-FR',
            },
          },
      { data },
      axiosOptions,
    ),
  )
  return res ? res.data : null
}

export function useQueryWrapper<T = any>(
  method,
  urlTemplate,
  params = {},
  options = {},
  filtersPaginationAndSort = null,
) {
  let url = urlTemplate
  try {
    url = urlcat(urlTemplate, params)
    if (!isEmpty(filtersPaginationAndSort?.filters)) {
      if (isEmpty(params)) {
        url = `${url}?${filtersPaginationAndSort.filters}`
      } else {
        url = `${url}&${filtersPaginationAndSort.filters}`
      }
    }
    if (!isEmpty(filtersPaginationAndSort?.pagination)) {
      if (isEmpty(params) && isEmpty(filtersPaginationAndSort?.filters)) {
        url = `${url}?${filtersPaginationAndSort.pagination}`
      } else {
        url = `${url}&${filtersPaginationAndSort.pagination}`
      }
    }
    if (!isEmpty(filtersPaginationAndSort?.sort)) {
      if (
        isEmpty(params) &&
        isEmpty(filtersPaginationAndSort?.filters) &&
        isEmpty(filtersPaginationAndSort?.pagination)
      ) {
        url = `${url}?${filtersPaginationAndSort.sort}`
      } else {
        url = `${url}&${filtersPaginationAndSort.sort}`
      }
    }
  } catch (error) {
    url = '[FTEL-ERROR] : Url malformée, vérifiez les paramètres de la query'
  }

  const queryKey = compact([
    urlTemplate,
    params,
    filtersPaginationAndSort?.filters,
    filtersPaginationAndSort?.pagination,
    filtersPaginationAndSort?.sort,
  ])
  const query = useQuery<T>(queryKey, () => fetcher(method, url), options)

  return { ...query, queryKey }
}

type MutationFunctionVariables = { data?: object; [key: string]: any }

export function useMutationWrapper<T = any>(
  method: HttpMethod,
  urlTemplate: string,
  options,
  axiosOptions?: AxiosRequestConfig,
) {
  return useMutation<T, unknown, MutationFunctionVariables>((variables?) => {
    const objectVariables = variables || {}
    const { data, ...params } = objectVariables
    return fetcher(method, urlcat(urlTemplate, params), data, axiosOptions)
  }, options)
}

export const fakeFetcher = (data) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(data), 800)
  })
}

export const useFakeMutation = (fakeData, options) => {
  return useMutation(() => fakeFetcher(fakeData), options)
}

export const useFakeQuery = (queryKey, fakeData, options) => {
  return useQuery(queryKey, () => fakeFetcher(fakeData), options)
}
