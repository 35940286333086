import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { USER, USERS, USER_ME } from './templates'
import { User } from './types'

export function useUserMeQuery(params?, options?) {
  return useQueryWrapper<User>('GET', USER_ME, params, options)
}

export function useUserQuery(params?, options?) {
  return useQueryWrapper<User>('GET', USER, params, options)
}

export function useUsersQuery(params?, options?, filtersPaginationAndSort?) {
  return useQueryWrapper<PaginatedType<User>>(
    'GET',
    USERS,
    params,
    options,
    filtersPaginationAndSort,
  )
}
