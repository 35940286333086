import { Cancel, People } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useAuth } from 'contexts/AuthContext'
import { INFO, useSnackbar } from 'contexts/SnackbarContext'
import { getImpersonate, impersonate, unImpersonate } from 'helpers/impersonate'
import { isSupervisor } from 'helpers/role'
import { isNull } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImpersonateDialogConfirm from './Components/ImpersonateDialogConfirm'
import ImpersonateDialogList from './Components/ImpersonateDialogList'

function ImpersonateManager({ open }) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { popSnackbar } = useSnackbar()
  const [openDialog, setOpenDialog] = useState(false)
  const { impersonateId, impersonateName } = getImpersonate()
  const impersonated = !isNull(impersonateId)
  const { user } = useAuth()
  const userIsSupervisor = user && isSupervisor(user.role.key)

  const handleClickOpen = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleSelect = async (id: string, username: string) => {
    setOpenDialog(false)
    popSnackbar(t('impersonate-success'), INFO)
    impersonate(id, username)
    queryClient.refetchQueries({ stale: true })
  }
  const handleDisconnect = async (id: string) => {
    setOpenDialog(false)
    popSnackbar(t('disconnect-success'), INFO)
    unImpersonate()
    queryClient.refetchQueries({ stale: true })
  }

  return (
    <Tooltip title={impersonateName ?? t('impersonate')}>
      <Box sx={{ width: 1 }}>
        {impersonated ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: open ? 'flex-start' : 'center',
                width: 1,
                px: 2,
              }}
            >
              <IconButton size="small" edge="start" onClick={handleClickOpen}>
                <Cancel sx={{ color: 'common.white' }} />
              </IconButton>
              {open && (
                <Typography sx={{ ml: 1, color: 'common.white' }}>
                  {impersonateName}
                </Typography>
              )}
            </Box>
            <ImpersonateDialogConfirm
              open={openDialog}
              onClose={handleClose}
              onConfirm={handleDisconnect}
            />
          </>
        ) : (
          userIsSupervisor && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: open ? 'flex-start' : 'center',
                  width: 1,
                  px: 2,
                }}
              >
                <IconButton
                  size="small"
                  aria-label="impersonate"
                  edge="start"
                  onClick={() => handleClickOpen()}
                  sx={{
                    height: (theme) => theme.spacing(5),
                    width: (theme) => theme.spacing(5),
                  }}
                >
                  <People
                    sx={{
                      color: 'common.white',
                    }}
                  />
                </IconButton>
                {open && (
                  <Typography sx={{ ml: 1, color: 'common.white' }}>
                    {t('impersonate')}
                  </Typography>
                )}
              </Box>
              <ImpersonateDialogList
                open={openDialog}
                onClose={handleClose}
                onSelect={handleSelect}
              />
            </>
          )
        )}
      </Box>
    </Tooltip>
  )
}

export default ImpersonateManager
