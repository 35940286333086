import { CssBaseline } from '@mui/material'
import 'animate.css'
import VersionNumber from 'components/atoms/VersionNumber'
import Snackbar from 'components/molecules/Snackbar'
import routes from 'config/routes'
import { AuthProvider } from 'contexts/AuthContext'
import { AxiosInterceptors } from 'contexts/AxiosInterceptors'
import { ConfirmProvider } from 'contexts/ConfirmContext'
import QueryClientProvider from 'contexts/QueryClientProvider'
import { SnackbarProvider } from 'contexts/SnackbarContext'
import ThemeProvider from 'contexts/ThemeProvider'
import React from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import './config/i18n'

function Router() {
  return useRoutes(routes)
}

function Entrypoint() {
  return (
    <ThemeProvider>
      <SnackbarProvider>
        <ConfirmProvider>
          <BrowserRouter>
            <QueryClientProvider>
              <AxiosInterceptors>
                <AuthProvider>
                  <CssBaseline />
                  <Snackbar />
                  <Router />
                  <VersionNumber />
                </AuthProvider>
              </AxiosInterceptors>
            </QueryClientProvider>
          </BrowserRouter>
        </ConfirmProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default Entrypoint
