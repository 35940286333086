import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Close } from '@mui/icons-material'
import DialogEscapeClose from 'components/templates/DialogEscapeClose'
import { useUsersQuery } from 'domains/users/queries'

function ImpersonateDialogList({ onClose, onSelect, open }) {
  const { t } = useTranslation()
  const { data: usersPaginated } = useUsersQuery({}, { enabled: open })

  return (
    <DialogEscapeClose onClose={onClose} open={open}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          width: 1,
        }}
      >
        <Typography variant="h4">{t('impersonate-choose-user')}</Typography>
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <List sx={{ pt: 0 }}>
        {usersPaginated &&
          usersPaginated.data?.map(({ id, email }) => (
            <ListItem disableGutters key={id}>
              <ListItemButton onClick={() => onSelect(id, email)} key={id}>
                <ListItemAvatar>
                  <Avatar>
                    <AccountCircleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={email} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </DialogEscapeClose>
  )
}

export default ImpersonateDialogList
