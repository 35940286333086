/* eslint-disable react/jsx-props-no-spreading */
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { isDistributor, isSeedSeller, isSugarFactory } from 'helpers/role'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TextFieldComponent from './components/Textfield'

function ProfileManager() {
  const { t } = useTranslation()

  const { user } = useAuth()
  const isSugarFactoryAccount = isSugarFactory(user?.role?.roleType)
  const isSeedSellerAccount = isSeedSeller(user?.role?.roleType)
  const isDistributorAccount = isDistributor(user?.role)
  if (!user) return <CircularProgress />
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'common.white',
          textAlign: 'end',
          borderRadius: 2,
          p: 2,
          width: '70%',
        }}
      >
        <Box
          sx={{
            width: 1,
            height: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            boxShadow: '-10px -10px 20px #F8F8F8, 10px 10px 20px #DCDDE2',
            borderRadius: 1,
            p: 2,
          }}
        >
          <Box sx={{ textAlign: 'center', pb: 2 }}>
            <Typography variant="h4">{t('profil')}</Typography>
          </Box>
          <Box
            sx={{
              width: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Grid container spacing={2} sx={{ mb: 1, flex: 1 }}>
              <Grid item xs={12} md={6} lg={2}>
                <Typography>{t('lastname')} :</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={10}>
                <TextFieldComponent value={user?.lastName} />
              </Grid>

              <Grid item xs={12} md={6} lg={2}>
                <Typography>{t('firstname')} :</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={10}>
                <TextFieldComponent value={user?.firstName} />
              </Grid>

              <Grid item xs={12} md={6} lg={2}>
                <Typography>{t('email')} :</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={10}>
                <TextFieldComponent value={user?.email} />
              </Grid>

              <Grid item xs={12} md={6} lg={2}>
                <Typography>{t('role')} :</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={10}>
                <TextFieldComponent value={user?.role?.name} />
              </Grid>

              {isSugarFactoryAccount && (
                <>
                  <Grid item xs={12} md={6} lg={2}>
                    <Typography>{t('sugar-factory')} :</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={10}>
                    <TextFieldComponent value={user?.sugarFactory?.name} />
                  </Grid>
                </>
              )}

              {isDistributorAccount && (
                <>
                  <Grid item xs={12} md={6} lg={2}>
                    <Typography>{t('distributor')} :</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={10}>
                    <TextFieldComponent
                      value={user?.distributors
                        .map((distributor) => distributor.name)
                        .join(' / ')}
                    />
                  </Grid>
                </>
              )}
              {isSeedSellerAccount && (
                <>
                  <Grid item xs={12} md={6} lg={2}>
                    <Typography>{t('seed-seller')} :</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={10}>
                    <TextFieldComponent value={user?.seedSeller?.name} />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ProfileManager
