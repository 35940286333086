import React from 'react'
import {
  QueryClient,
  QueryClientProvider as RQueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

function QueryClientProvider({ children }) {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
  })
  return (
    <RQueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </RQueryClientProvider>
  )
}

export default QueryClientProvider
