// import { useLocalStorage } from 'usehooks-ts'

export function impersonate(id: string, name: string) {
  window.localStorage.setItem('impersonate', id)
  window.localStorage.setItem('impersonateName', name)
}
export function unImpersonate() {
  window.localStorage.removeItem('impersonate')
  window.localStorage.removeItem('impersonateName')
}
export function getImpersonate() {
  const impersonateId = window.localStorage.getItem('impersonate')
  const impersonateName = window.localStorage.getItem('impersonateName')
  return { impersonateId, impersonateName }
}
