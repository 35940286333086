import { DefaultRole, RoleType } from 'domains/roles/enums'

export function isAdminPlus(roleKey) {
  return [DefaultRole.SUPERVISOR, DefaultRole.ADMIN_BAYER].includes(roleKey)
}

export function isSupervisor(roleKey) {
  return DefaultRole.SUPERVISOR === roleKey
}

export function isSupervisorOrSugarFactory(user) {
  return (
    DefaultRole.SUPERVISOR === user?.role.key ||
    RoleType.SUGAR_FACTORY === user?.role.roleType
  )
}

export function isAdmin(roleKey) {
  return DefaultRole.ADMIN_BAYER === roleKey
}

export function isComercialBayer(roleKey) {
  return DefaultRole.COMMERCIAL_BAYER === roleKey
}

export function isSupervisorOrDistributor(user) {
  return (
    DefaultRole.SUPERVISOR === user?.role.key ||
    RoleType.DISTRIBUTOR === user?.role.roleType
  )
}

export function isSugarFactoryAdmin(roleKey) {
  return DefaultRole.SUGAR_FACTORY_ADMIN === roleKey
}

export function isDistributor(role) {
  return (
    RoleType.DISTRIBUTOR === role?.roleType &&
    role?.key !== DefaultRole.COMMERCIAL_BAYER
  )
}

export function isSeedSeller(roleType) {
  return RoleType.SEED_SELLER === roleType
}

export function isSugarFactory(roleType) {
  return RoleType.SUGAR_FACTORY === roleType
}

export const getRoleTitle = (user) => {
  const isSugarFactoryAccount = isSugarFactory(user?.role?.roleType)
  const isDistributorAccount = isDistributor(user?.role)
  const isAdminAccount =
    isAdmin(user?.role?.key) || isComercialBayer(user?.role?.key)
  const isSeedSellerAccount = isSeedSeller(user?.role?.roleType)

  if (isSugarFactoryAccount) return 'sugar-factory-app'
  if (isDistributorAccount) return 'distributor-app'
  if (isAdminAccount) return 'bayer-app'
  if (isSeedSellerAccount) return 'seed-seller-app'
  return ''
}

export const translateRole = (role) => {
  const roleLower = role.toLowerCase().replaceAll('_', '-')
  return roleLower
}
