import { Operators } from 'enums/filters'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { keyBy, mapValues } from 'lodash'
import { Settings } from 'luxon'
import { initReactI18next } from 'react-i18next'

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fr-FR',
    supportedLngs: ['fr-FR', 'en-GB'],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      allowMultiLoading: true,
      loadPath: `${process.env.REACT_APP_API_URL}/resource-translations?filters[cultureKey][$${Operators.EQUAL_I}]={{lng}}&pagination[page]=1`,
      parse: (data) => {
        return mapValues(keyBy(JSON.parse(data).data, 'resourceKey'), 'value')
      },
    },
    detection: {
      order: ['localStorage'],
      caches: ['localStorage', 'cookie'],
      cookieMinutes: 80,
    },
  })

Settings.defaultLocale = 'fr'

export default i18n
