import LoadingScreen from 'components/atoms/LoadingScreen'
import React from 'react'
import StandardLayout from '../StandardLayout'

function LoadingStandardLayout() {
  return (
    <StandardLayout>
      <LoadingScreen />
    </StandardLayout>
  )
}

export default LoadingStandardLayout
