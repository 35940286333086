/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import i18n from 'config/i18n'
import { clearJwtToken } from 'domains/helpers'
import { FEATURES } from 'domains/permissions/enums'
import { DefaultRole } from 'domains/roles/enums'
import {
  useAuthenticateMutation,
  useRevokeTokenMutation,
} from 'domains/users/mutations'
import { useUserMeQuery } from 'domains/users/queries'
import { USER_ME } from 'domains/users/templates'
import { LOGIN_PATH } from 'enums/paths'
import { unImpersonate } from 'helpers/impersonate'
import { get } from 'lodash'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'

type AuthContextValues = {
  user: any
  loading: boolean
  login: (values: any) => Promise<void>
  logout: () => Promise<AxiosResponse<any, any>>
  can: (feature: FEATURES) => boolean
  is: (role: DefaultRole) => boolean
  refetchUser: any
}

const AuthContext = createContext<AuthContextValues>(undefined)

function AuthProvider({ children }) {
  const authenticate = useAuthenticateMutation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const revokeToken = useRevokeTokenMutation()
  const [token, setToken] = useLocalStorage('token', null)
  const {
    data: user,
    refetch,
    isLoading,
    fetchStatus,
  } = useUserMeQuery(null, { enabled: !!token })
  const loading = isLoading && fetchStatus !== 'idle'

  useEffect(() => {
    if (
      user &&
      user.defaultCultureKey !== null &&
      i18n.language !== user.defaultCultureKey
    ) {
      i18n.changeLanguage(user.defaultCultureKey)
    }
  }, [user])

  const login = useCallback(async (values) => {
    const { token: newToken, user: userResponse } =
      await authenticate.mutateAsync({
        data: values,
      })
    setToken(newToken)
    queryClient.setQueryData([USER_ME], userResponse)
  }, [])

  const logout = useCallback(async () => {
    await revokeToken.mutateAsync(null)
    clearJwtToken()
    unImpersonate()
    navigate(LOGIN_PATH)
    return null
  }, [])

  const can = useCallback(
    (feature: FEATURES): boolean => {
      return get(user, 'role._Features', []).includes(feature)
    },
    [user],
  )

  const is = useCallback(
    (role: DefaultRole): boolean => {
      return get(user, 'role.key') === role
    },
    [user],
  )

  const values = useMemo(
    () => ({
      user,
      loading,
      login,
      logout,
      can,
      is,
      refetchUser: refetch,
    }),
    [user, loading, login, logout, can, is, refetch],
  )

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
