import { TypographyOptions } from '@mui/material/styles/createTypography'

const typography: TypographyOptions = {
  fontFamily: 'Roboto',
  h1: {
    fontWeight: 700,
    fontSize: '2.25rem', // 36px
  },
  h2: {
    fontWeight: 700,
    fontSize: '1.875rem', // 30px
  },
  h3: {
    fontWeight: 700,
    fontSize: '1.25rem', // 20px
  },
  h4: {
    fontWeight: 600,
    fontSize: '1.25rem', // 20px
  },
  h5: {
    fontWeight: 600,
    fontSize: '1.25rem', // 20px
  },
  h6: {
    fontWeight: 600,
    fontSize: '1.25rem', // 20px
  },
  body1: {
    fontWeight: 400,
    fontSize: '1rem', // 16px
  },
  body2: {
    fontWeight: 400,
    fontSize: '0.875rem', // 14px
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: '1.5rem', // 24px
    textTransform: 'uppercase',
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: '0.75rem', // 12px
  },
  button: {
    fontWeight: 700,
    textTransform: 'none',
    fontSize: '1rem', // 16px
  },
}

export default typography

// https://mui.com/material-ui/customization/typography/
