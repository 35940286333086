import { Menu } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useAuth } from 'contexts/AuthContext'
import { AppParametersAuthorizedToRead } from 'domains/appParams/enums'
import { useAppParamAuthorizedToReadQuery } from 'domains/appParams/queries'
import { DefaultRole } from 'domains/roles/enums'
import React from 'react'
import Drawer from './components/Drawer'
import {
  DRAWER_EXTENDABLE_WIDTH,
  DRAWER_WIDTH,
  HEIGHT_ENV_BANNER,
} from './enums'

function AdminLayout({ children }) {
  const [open, setOpen] = React.useState(false)
  const { is } = useAuth()
  const isSupervisor = is(DefaultRole.SUPERVISOR)
  const { data: envAppParams } = useAppParamAuthorizedToReadQuery(
    {
      key: AppParametersAuthorizedToRead.ENV,
    },
    { enabled: isSupervisor },
  )

  const heightBanner =
    envAppParams?.value && isSupervisor ? HEIGHT_ENV_BANNER : 0
  return (
    <>
      {envAppParams?.value && isSupervisor && (
        <Box
          sx={{
            width: 1,
            height: (theme) => theme.spacing(heightBanner),
            textAlign: 'center',
            bgcolor: 'warning.light',
          }}
        >
          <Typography>{envAppParams.value}</Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          height: (theme) => `calc(100vh - ${heightBanner}px)`,
        }}
      >
        <Drawer
          open={open}
          setOpen={setOpen}
          drawerWidth={open ? DRAWER_EXTENDABLE_WIDTH : DRAWER_WIDTH}
          heightBanner={heightBanner}
        />
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              display: { xs: 'block', sm: 'none' },
              bgcolor: 'primary.main',
            }}
          >
            <IconButton onClick={() => setOpen(true)}>
              <Menu sx={{ color: 'common.white' }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              p: 3,
              overflowY: 'auto',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default AdminLayout
