import { useMutationWrapper } from 'domains/helpers'
import {
  AUTHENTICATE,
  REFRESH_TOKEN,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  REVOKE_TOKEN,
  USER,
  USERS,
  VALIDATION_RESET_PASSWORD,
  ACCEPT_LEGAL_NOTICE,
} from './templates'
import { AuthenticationResponse } from './types'

export function useAuthenticateMutation(options?) {
  return useMutationWrapper<AuthenticationResponse>(
    'POST',
    AUTHENTICATE,
    options,
    {
      withCredentials: true,
    },
  )
}

export function useRefreshTokenMutation(options?) {
  return useMutationWrapper('POST', REFRESH_TOKEN, options)
}

export function useRevokeTokenMutation(options?) {
  return useMutationWrapper('POST', REVOKE_TOKEN, options, {
    withCredentials: true,
  })
}

export function useResetPasswordMutation(options?) {
  return useMutationWrapper('POST', RESET_PASSWORD, options)
}

export function useValidationResetPasswordMutation(options?) {
  return useMutationWrapper('POST', VALIDATION_RESET_PASSWORD, options)
}

export function useForgotPasswordMutation(options?) {
  return useMutationWrapper('POST', FORGOT_PASSWORD, options)
}

export function useDeleteUserMutation(options?) {
  return useMutationWrapper('DELETE', USER, options)
}

export function usePutUserMutation(options?) {
  return useMutationWrapper('PUT', USER, options)
}

export function usePatchUserMutation(options?) {
  return useMutationWrapper('PATCH', USER, options)
}

export function useCreateUserMutation(options?) {
  return useMutationWrapper('POST', USERS, options)
}

export function useValidationUserLegalNoticeMutation(params?, options?) {
  return useMutationWrapper('POST', ACCEPT_LEGAL_NOTICE, params, options)
}
