import ProfileManager from 'components/organisms/ProfileManager'
import AdminLayout from 'components/routes/Admin/Layout'
import AppLayout from 'components/routes/App/Layout'
import Logout from 'components/routes/Logout'
import { useAuth } from 'contexts/AuthContext'
import { FEATURES } from 'domains/permissions/enums'
import {
  APP_PARAMS_PATH,
  EMAIL_MANAGEMENT_TEMPLATES_PATH,
  SUGAR_FACTORIES_PATH,
} from 'enums/paths'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const AppParams = React.lazy(() => import('components/routes/Admin/AppParams'))
const Logs = React.lazy(() => import('components/routes/Admin/Logs'))
const Internationalization = React.lazy(
  () => import('components/routes/Admin/Internationalization'),
)
const MailManagementFusionFieldsCreate = React.lazy(
  () => import('components/routes/Admin/MailManagement/FusionFields/Create'),
)
const MailManagementFusionFields = React.lazy(
  () => import('components/routes/Admin/MailManagement/FusionFields'),
)
const MailManagementLayout = React.lazy(
  () => import('components/routes/Admin/MailManagement/Layout'),
)
const MailManagementTemplatesCreate = React.lazy(
  () => import('components/routes/Admin/MailManagement/Templates/Create'),
)
const MailManagementTemplatesEdit = React.lazy(
  () => import('components/routes/Admin/MailManagement/Templates/Edit'),
)
const MailManagementTemplates = React.lazy(
  () => import('components/routes/Admin/MailManagement/Templates'),
)
const MailManagementTemplatesTest = React.lazy(
  () => import('components/routes/Admin/MailManagement/TemplatesTest'),
)
const MailManagementTest = React.lazy(
  () => import('components/routes/Admin/MailManagement/Test'),
)
const AdminUsers = React.lazy(() => import('components/routes/Admin/Users'))
const AdminSeedSellers = React.lazy(
  () => import('components/routes/Admin/SeedSellers'),
)
const AdminSeeds = React.lazy(() => import('components/routes/Admin/Seeds'))
const AdminSugarFactories = React.lazy(
  () => import('components/routes/Admin/SugarFactories'),
)
const AdminProducts = React.lazy(
  () => import('components/routes/Admin/Products'),
)
const AdminLegalNotices = React.lazy(
  () => import('components/routes/Admin/LegalNotices'),
)
const AdminDistributors = React.lazy(
  () => import('components/routes/Admin/Distributors'),
)
const AdminRoles = React.lazy(() => import('components/routes/Admin/Roles'))
const AdminPermissions = React.lazy(
  () => import('components/routes/Admin/Permissions'),
)
const NotFound = React.lazy(
  () => import('components/routes/ErrorPages/NotFound'),
)
const UnderConstruction = React.lazy(
  () => import('components/routes/ErrorPages/UnderConstruction'),
)
const ForgotPassword = React.lazy(
  () => import('components/routes/ForgotPassword'),
)
const ForgotPasswordSuccess = React.lazy(
  () => import('components/routes/ForgotPassword/Success'),
)
const Login = React.lazy(() => import('components/routes/Login'))
const Dashboard = React.lazy(() => import('components/routes/App/Dashboard'))
const ResetPassword = React.lazy(
  () => import('components/routes/ResetPassword'),
)
const AdminPrivacyPolicy = React.lazy(
  () => import('components/routes/Admin/PrivacyPolicies'),
)
const ReservationForm = React.lazy(
  () => import('components/routes/App/Reservations/form'),
)
const PrivacyPolicy = React.lazy(
  () => import('components/routes/PrivacyPolicy'),
)

function RequireAuth({ children }) {
  const { user, loading } = useAuth()
  const location = useLocation()

  if (!user && !loading) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

function RequireRights({ children, feature }) {
  const { can, loading } = useAuth()
  const location = useLocation()

  if (!can(feature) && !loading) {
    return <Navigate to="/not-found" state={{ from: location }} replace />
  }

  return children
}

const routes = [
  {
    path: '',
    element: (
      <RequireAuth>
        <AppLayout />
      </RequireAuth>
    ),
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: 'profile',
        element: <ProfileManager />,
      },
      {
        path: 'reservations',
        children: [
          {
            path: 'create',
            element: (
              <RequireRights feature={FEATURES.CREATION_RESERVATION}>
                <ReservationForm />,
              </RequireRights>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <RequireRights feature={FEATURES.EDITION_RESERVATION}>
                <ReservationForm />,
              </RequireRights>
            ),
          },
        ],
      },
    ],
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'logout',
    element: <Logout />,
  },
  {
    path: 'forgot-password',
    children: [
      {
        index: true,
        element: <ForgotPassword />,
      },
      {
        path: 'success',
        element: <ForgotPasswordSuccess />,
      },
    ],
  },
  {
    path: 'reset-password/:key',
    element: <ResetPassword />,
  },
  {
    path: 'admin',
    element: (
      <RequireAuth>
        <RequireRights feature={FEATURES.ADMIN_ALL_RIGHTS}>
          <AdminLayout />
        </RequireRights>
      </RequireAuth>
    ),
    children: [
      { index: true, element: <Navigate to={SUGAR_FACTORIES_PATH} /> },
      {
        path: 'app-params',
        element: <AppParams />,
      },
      {
        path: 'logs',
        element: <Logs />,
      },
      {
        path: 'privacy-policies',
        element: <AdminPrivacyPolicy />,
      },
      {
        path: 'email-management',
        element: <MailManagementLayout />,
        children: [
          {
            index: true,
            element: <Navigate to={EMAIL_MANAGEMENT_TEMPLATES_PATH} />,
          },
          {
            path: 'templates',
            children: [
              { index: true, element: <MailManagementTemplates /> },
              {
                path: 'create',
                element: <MailManagementTemplatesCreate />,
              },
              {
                path: ':id/edit',
                element: <MailManagementTemplatesEdit />,
              },
            ],
          },
          {
            path: 'layout',
            element: <UnderConstruction />,
          },
          {
            path: 'fusion-fields',
            element: <MailManagementFusionFields />,
          },
          {
            path: 'fusion-fields/create',
            element: <MailManagementFusionFieldsCreate />,
          },
          {
            path: 'test',
            element: <MailManagementTest />,
          },
          {
            path: 'templates-test',
            element: <MailManagementTemplatesTest />,
          },

          {
            path: '*',
            element: <Navigate to={EMAIL_MANAGEMENT_TEMPLATES_PATH} />,
          },
        ],
      },
      {
        path: 'internationalization',
        element: <Internationalization />,
      },
      {
        path: 'users',
        element: <AdminUsers />,
      },
      {
        path: 'seed-sellers',
        element: <AdminSeedSellers />,
      },
      {
        path: 'seeds',
        element: <AdminSeeds />,
      },
      {
        path: 'sugar-factories',
        element: <AdminSugarFactories />,
      },
      {
        path: 'products',
        element: <AdminProducts />,
      },
      {
        path: 'legal-notices',
        element: <AdminLegalNotices />,
      },
      {
        path: 'distributors',
        element: <AdminDistributors />,
      },
      {
        path: 'roles',
        element: <AdminRoles />,
      },
      {
        path: 'permissions',
        element: <AdminPermissions />,
      },
      { path: '*', element: <Navigate to={APP_PARAMS_PATH} /> },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]

export default routes
