import {
  AdminPanelSettings,
  ArrowBack,
  DisplaySettings,
  Email,
  FactoryOutlined,
  FormatListBulleted,
  GavelRounded,
  Group,
  Language,
  LocalPolice,
  Lock,
  Sanitizer,
  Spa,
  Storefront,
  WaterDrop,
} from '@mui/icons-material'
import { useAuth } from 'contexts/AuthContext'
import {
  ADMIN_PRIVACY_POLICY_PATH,
  APP_PARAMS_PATH,
  DISTRIBUTORS_PATH,
  EMAIL_MANAGEMENT_PATH,
  HOME_PATH,
  INTERNATIONALIZATION_PATH,
  LEGAL_NOTICES_PATH,
  LOGS_PATH,
  PERMISSIONS_PATH,
  PRODUCTS_PATH,
  ROLES_PATH,
  SEEDS_PATH,
  SEED_SELLERS_PATH,
  SUGAR_FACTORIES_PATH,
  USERS_PATH,
} from 'enums/paths'
import { isSupervisor } from 'helpers/role'
import { useTranslation } from 'react-i18next'

export const LINK = 'link'
export const DIVIDER = 'divider'

export function useGetMenuItems() {
  const { t } = useTranslation()
  const { user } = useAuth()

  return [
    {
      key: 'home',
      type: LINK,
      title: t('back-to-app'),
      icon: ArrowBack,
      to: HOME_PATH,
    },
    {
      key: 'divider0',
      type: DIVIDER,
    },
    {
      key: 'sugar-factories',
      type: LINK,
      title: t('sugar-factories'),
      icon: FactoryOutlined,
      to: SUGAR_FACTORIES_PATH,
    },
    {
      key: 'distributors',
      type: LINK,
      title: t('distributors'),
      icon: Storefront,
      to: DISTRIBUTORS_PATH,
    },
    {
      key: 'products',
      type: LINK,
      title: t('products'),
      icon: Sanitizer,
      to: PRODUCTS_PATH,
    },
    {
      key: 'seed-sellers',
      type: LINK,
      title: t('seed-sellers'),
      icon: WaterDrop,
      to: SEED_SELLERS_PATH,
    },
    {
      key: 'seeds',
      type: LINK,
      title: t('seeds'),
      icon: Spa,
      to: SEEDS_PATH,
    },
    {
      key: 'divider1',
      type: DIVIDER,
    },
    {
      key: 'app-params',
      type: LINK,
      title: t('app-params'),
      icon: DisplaySettings,
      to: APP_PARAMS_PATH,
    },
    {
      key: 'mails',
      type: LINK,
      title: t('mail-templates'),
      icon: Email,
      to: EMAIL_MANAGEMENT_PATH,
    },
    {
      key: 'users',
      type: LINK,
      title: t('users'),
      icon: Group,
      to: USERS_PATH,
    },
    ...(isSupervisor(user?.role?.key)
      ? [
          {
            key: 'roles',
            type: LINK,
            title: t('roles'),
            icon: LocalPolice,
            to: ROLES_PATH,
          },
          {
            key: 'permissions',
            type: LINK,
            title: t('permissions'),
            icon: Lock,
            to: PERMISSIONS_PATH,
          },
        ]
      : []),
    {
      key: 'logs',
      type: LINK,
      title: t('logs'),
      icon: FormatListBulleted,
      to: LOGS_PATH,
    },
    {
      key: 'legal-notices',
      type: LINK,
      title: t('legal-notices'),
      icon: GavelRounded,
      to: LEGAL_NOTICES_PATH,
    },
    {
      key: 'privacy-policies',
      type: LINK,
      title: t('privacy-policies'),
      icon: AdminPanelSettings,
      to: ADMIN_PRIVACY_POLICY_PATH,
    },
    isSupervisor(user?.role?.key)
      ? {
          key: 'i18n',
          type: LINK,
          title: t('i18n'),
          icon: Language,
          to: INTERNATIONALIZATION_PATH,
        }
      : {},
  ]
}
