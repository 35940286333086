import { Add, GavelRounded, Person, Settings } from '@mui/icons-material'
import {
  Box,
  Divider,
  ListItemButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import MUIAppBar from '@mui/material/AppBar'
import logo from 'assets/images/logo.png'
import IconBubbleButton from 'components/molecules/IconBubbleButton'
import LegalNoticeReadDialog from 'components/organisms/LegalNoticeReadDialog'
import { useAuth } from 'contexts/AuthContext'
import { FEATURES } from 'domains/permissions/enums'
import { DefaultRole } from 'domains/roles/enums'
import {
  ADMIN_PATH,
  HOME_PATH,
  PROFILE,
  RESERVATIONS_CREATE_PATH,
} from 'enums/paths'
import { getRoleTitle } from 'helpers/role'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { HEIGHT_APP_BAR } from '../../enums'

function AppBar({ drawerWidth, heightBanner }) {
  const { t } = useTranslation()
  const { user, is, logout, can } = useAuth()
  const navigate = useNavigate()
  const [openDialog, setOpenDialog] = React.useState(false)
  const handleNavigateToAdmin = () => {
    navigate(ADMIN_PATH)
  }
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleOpenDialog = () => {
    setOpenDialog(true)
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleLogout = () => {
    logout()
    handleClose()
  }
  const goToProfil = () => {
    navigate(PROFILE)
  }

  const hasRight = can(FEATURES.CREATION_RESERVATION)

  return (
    <MUIAppBar
      position="fixed"
      sx={{
        ml: { sm: `${drawerWidth}px` },
        zIndex: (theme) => theme.zIndex.drawer + 1,
        mt: heightBanner,
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          height: HEIGHT_APP_BAR,
        }}
      >
        <Box
          sx={{
            height: (theme) => theme.spacing(10),
            width: (theme) => theme.spacing(10),
            '& img': {
              width: '100%',
            },
            my: 1,
            display: { xs: 'none', md: 'flex' },
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate(HOME_PATH)
          }}
        >
          <img src={logo} alt="logo-convisio" />
        </Box>
        <Box>
          <Typography
            variant="h1"
            noWrap
            component="div"
            sx={{ color: 'common.black' }}
          >
            {t('orders-management')}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: 'common.black' }}>
            {t(getRoleTitle(user))}
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          {hasRight && (
            <Tooltip title={t('create-reservation')}>
              <IconBubbleButton
                onClick={() => {
                  navigate(RESERVATIONS_CREATE_PATH)
                }}
              >
                <Add
                  sx={{
                    p: { xs: 0.25, md: 1 },
                    width: '100%',
                    height: '100%',
                  }}
                />
              </IconBubbleButton>
            </Tooltip>
          )}

          <IconBubbleButton onClick={handleOpenDialog}>
            <GavelRounded
              sx={{
                p: { xs: 0.25, md: 1 },
                width: '100%',
                height: '100%',
              }}
            />
          </IconBubbleButton>

          <IconBubbleButton onClick={handleClick}>
            <Person
              sx={{
                p: { xs: 0.25, md: 1 },
                width: '100%',
                height: '100%',
              }}
            />
          </IconBubbleButton>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <ListItemButton disabled>
              <Typography>{`${user?.firstName} ${user?.lastName}`}</Typography>
            </ListItemButton>
            <Divider />
            <MenuItem onClick={goToProfil}>{t('profil')}</MenuItem>
            <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
          </Menu>
          {(is(DefaultRole.SUPERVISOR) || is(DefaultRole.ADMIN_BAYER)) && (
            <IconBubbleButton onClick={handleNavigateToAdmin}>
              <Settings
                sx={{
                  p: { xs: 0.25, md: 1 },
                  width: '100%',
                  height: '100%',
                }}
              />
            </IconBubbleButton>
          )}
        </Box>
      </Toolbar>
      <LegalNoticeReadDialog open={openDialog} onClose={handleCloseDialog} />
    </MUIAppBar>
  )
}

export default AppBar
