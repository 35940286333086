import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import AppBar from 'components/templates/StandardLayout/components/AppBar'
import { useAuth } from 'contexts/AuthContext'
import { AppParametersAuthorizedToRead } from 'domains/appParams/enums'
import { useAppParamAuthorizedToReadQuery } from 'domains/appParams/queries'
import { DefaultRole } from 'domains/roles/enums'
import React from 'react'
import { DRAWER_WIDTH, HEIGHT_APP_BAR, HEIGHT_ENV_BANNER } from './enums'
import LegalNoticeAcceptanceLayout from '../LegalNoticeAcceptanceLayout'

function StandardLayout({ children }) {
  const { is } = useAuth()
  const isSupervisor = is(DefaultRole.SUPERVISOR)
  const { data: envAppParams } = useAppParamAuthorizedToReadQuery(
    {
      key: AppParametersAuthorizedToRead.ENV,
    },
    { enabled: isSupervisor },
  )

  const heightBanner =
    envAppParams?.value && isSupervisor ? HEIGHT_ENV_BANNER : 0
  return (
    <LegalNoticeAcceptanceLayout>
      {envAppParams?.value && isSupervisor && (
        <Box
          sx={{
            width: 1,
            height: (theme) => theme.spacing(heightBanner),
            textAlign: 'center',
            bgcolor: 'warning.light',
          }}
        >
          <Typography>{envAppParams.value}</Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          height: (theme) => `calc(100vh - ${heightBanner}px)`,
          '& .MuiPaper-root': {
            bgcolor: 'common.white',
          },
        }}
      >
        <AppBar drawerWidth={DRAWER_WIDTH} heightBanner={heightBanner} />
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'hidden',
          }}
        >
          <Toolbar sx={{ height: HEIGHT_APP_BAR, flexShrink: 0 }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflowY: 'auto',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </LegalNoticeAcceptanceLayout>
  )
}

export default StandardLayout
