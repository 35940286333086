import React from 'react'
import { Dialog } from '@mui/material'

function DialogEscapeClose({ children, onClose, open = false, ...props }) {
  const handleCloseDialog = (event) => {
    if (event?.code === 'Escape') {
      onClose()
    }
  }
  return (
    <Dialog
      open={open}
      onClose={(event) => handleCloseDialog(event)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      fullWidth
    >
      {children}
    </Dialog>
  )
}

export default DialogEscapeClose
