import AdminLayout from 'components/templates/AdminLayout'
import SuspensedRoute from 'components/templates/SuspensedRoute'
import React from 'react'
import { Outlet } from 'react-router-dom'

function Admin() {
  return (
    <SuspensedRoute>
      <AdminLayout>
        <Outlet />
      </AdminLayout>
    </SuspensedRoute>
  )
}

export default Admin
