import Box from '@mui/material/Box'
import MUIDrawer from '@mui/material/Drawer'
import * as React from 'react'
import MenuItems from '../MenuItems'

function Drawer({ open, setOpen, drawerWidth, heightBanner }) {
  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
        '& .MuiPaper-root': {
          boxShadow: 1,
          mt: heightBanner,
          bgcolor: 'primary.main',
        },
      }}
    >
      <MUIDrawer
        variant="temporary"
        open={open}
        onClose={() => setOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            bgcolor: 'primary.main',
          },
        }}
      >
        <MenuItems setOpen={setOpen} open={open} />
      </MUIDrawer>
      <MUIDrawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            bgcolor: 'primary.main',
          },
        }}
        open
      >
        <MenuItems setOpen={setOpen} open={open} />
      </MUIDrawer>
    </Box>
  )
}

export default Drawer
