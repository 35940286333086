import { Close } from '@mui/icons-material'
import { DialogContent, DialogTitle, Box, IconButton } from '@mui/material'
import DialogEscapeClose from 'components/templates/DialogEscapeClose'
import { useAuth } from 'contexts/AuthContext'
import { useLastLegalNoticesQuery } from 'domains/legalNotices/queries'
import parse from 'html-react-parser'
import React from 'react'
import { useTranslation } from 'react-i18next'

function LegalNoticeReadDialog({ open, onClose }) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { data: LegalNotice } = useLastLegalNoticesQuery(null, {
    enabled: !!user && open,
  })

  return (
    <DialogEscapeClose open={open} onClose={onClose}>
      <DialogTitle variant="h3">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {t('legal-notices')}
          <IconButton size="small" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ '&.MuiDialogContent-root': { pt: 1 } }}>
        {parse(LegalNotice?.description ?? '')}
      </DialogContent>
    </DialogEscapeClose>
  )
}
export default LegalNoticeReadDialog
