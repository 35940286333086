export const USER_ME = '/users/me'
export const AUTHENTICATE = '/users/authenticate'
export const REFRESH_TOKEN = '/users/refresh-token'
export const REVOKE_TOKEN = '/users/revoke-token'
export const RESET_PASSWORD = '/users/reset-forgot-password'
export const VALIDATION_RESET_PASSWORD = '/users/validation-reset-password'
export const FORGOT_PASSWORD = '/users/forgot-password'
export const USERS = '/users'
export const USER = '/users/:id'
export const ACCEPT_LEGAL_NOTICE = '/users/accept-legal-notice'
