import { Components } from '@mui/material'

const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      '#root': {
        height: '100vh',
      },
    },
  },
  MuiInputBase: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiInputLabel: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiIconButton: {
    defaultProps: {
      size: 'small',
    },
  },
}

export default components

// https://mui.com/material-ui/customization/theme-components/
