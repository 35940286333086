import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { LAST_LEGAL_NOTICE, LEGAL_NOTICE, LEGAL_NOTICES } from './templates'
import { LegalNotice } from './types'

export function useLegalNoticeQuery(params?, options?) {
  return useQueryWrapper<LegalNotice>('GET', LEGAL_NOTICE, params, options)
}

export function useLegalNoticesQuery(
  params?,
  options?,
  filtersPaginationAndSort?,
) {
  return useQueryWrapper<PaginatedType<LegalNotice>>(
    'GET',
    LEGAL_NOTICES,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useLastLegalNoticesQuery(params?, options?) {
  return useQueryWrapper<LegalNotice>('GET', LAST_LEGAL_NOTICE, params, options)
}
