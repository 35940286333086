import { Box } from '@mui/material'
import React from 'react'

function IconBubbleButton({
  children,
  color = 'primary.main',
  onClick = null,
  sx = {},
}) {
  return (
    <Box
      sx={{
        bgcolor: color,
        color: 'common.white',
        height: (theme) => ({
          xs: theme.spacing(3),
          md: theme.spacing(5),
        }),
        width: (theme) => ({
          xs: theme.spacing(3),
          md: theme.spacing(5),
        }),
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: onClick !== null ? 'pointer' : 'initial',
        ml: 1,
        mb: 0.5,
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  )
}

export default IconBubbleButton
