/* eslint-disable react/jsx-props-no-spreading */
import { TextField } from '@mui/material'
import React from 'react'

function TextFieldComponent({ value }) {
  return (
    <TextField
      id="outlined-required"
      defaultValue={value}
      sx={{ backgroundColor: 'common.white' }}
      disabled
    />
  )
}

export default TextFieldComponent
