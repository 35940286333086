import axios from 'axios'
import { ERROR, useSnackbar, WARNING } from 'contexts/SnackbarContext'
import { axiosInstance, clearJwtToken } from 'domains/helpers'
import { useRefreshTokenMutation } from 'domains/users/mutations'
import { REFRESH_TOKEN } from 'domains/users/templates'
import { FORBIDDEN, UNAUTHORIZED } from 'enums/httpCodes'
import { LOGIN_PATH } from 'enums/paths'
import { first, flatten, get, isEmpty, values } from 'lodash'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'

export function AxiosInterceptors({ children }) {
  const [loading, setLoading] = useState(true)
  const [, setToken] = useLocalStorage('token', null)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { popSnackbar } = useSnackbar()
  const refreshTokenMutation = useRefreshTokenMutation()
  const refreshTokenPromise = useRef(null)

  const unauthorized = useCallback(
    (error?) => {
      popSnackbar(t('must-authenticate'), WARNING)
      clearJwtToken()
      navigate(LOGIN_PATH)
      return error
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, popSnackbar, t],
  )

  async function refreshToken() {
    try {
      const newToken = await refreshTokenMutation.mutateAsync(null)
      setToken(newToken)
      return newToken
    } catch (error) {
      return unauthorized()
    }
  }

  const resumeRequest = async (error) => {
    const req = error.config

    const newToken = await refreshTokenPromise.current?.promise

    req.headers = {
      ...req.headers,
      Authorization: `Bearer ${newToken}`,
    }
    return axios.request(req)
  }

  const handleExpiredToken = async (error) => {
    const timeout = refreshTokenPromise.current?.timeout || DateTime.now()

    if (isEmpty(refreshTokenPromise.current) || timeout < DateTime.now()) {
      refreshTokenPromise.current = {
        promise: refreshToken(),
        timeout: DateTime.now().plus({ minutes: 2 }),
      }
    }

    return resumeRequest(error)
  }

  const popSnackbarIfAvailable = (error) => {
    try {
      const data = get(error, 'response.data')
      const firstError = first(flatten(values(data)))

      if (!isEmpty(firstError)) {
        return popSnackbar(firstError, ERROR)
      }
      if (error.response && error.response.status !== UNAUTHORIZED) {
        return popSnackbar(t('error'), ERROR)
      }
      return null
    } catch (err) {
      return null
    }
  }

  const handleForbidden = () => {
    popSnackbar(t('forbidden'), ERROR)
  }

  useEffect(() => {
    const responseInterceptor = (response) => {
      return response
    }

    const errorInterceptor = (error) => {
      popSnackbarIfAvailable(error)
      if (error?.config?.url === REFRESH_TOKEN) {
        return Promise.reject(error)
      }
      switch (error.response.status) {
        case UNAUTHORIZED:
          return handleExpiredToken(error)
        case FORBIDDEN:
          return handleForbidden()
        default:
          return Promise.reject(error)
      }
    }

    const authInterceptor = axiosInstance.interceptors.response.use(
      responseInterceptor,
      errorInterceptor,
    )

    setLoading(false)
    return () => axiosInstance.interceptors.response.eject(authInterceptor)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return loading ? null : children
}
