import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
} from '@mui/material'
import DialogEscapeClose from 'components/templates/DialogEscapeClose'
import { useAuth } from 'contexts/AuthContext'
import { useValidationUserLegalNoticeMutation } from 'domains/users/mutations'
import parse from 'html-react-parser'
import React from 'react'
import { useTranslation } from 'react-i18next'

function LegalNoticeAcceptanceDialog({
  open,
  LegalNoticeContent,
  LegalNoticeId,
}) {
  const { t } = useTranslation()
  const { refetchUser } = useAuth()
  const updateUserLegalNotice = useValidationUserLegalNoticeMutation()
  const [isAccepted, setIsAccepted] = React.useState(false)

  const acceptLegalNotice = async () => {
    const data = {
      legalNoticeId: LegalNoticeId,
    }
    if (isAccepted) {
      await updateUserLegalNotice.mutateAsync({
        data,
      })

      refetchUser()
    }
  }

  return (
    <DialogEscapeClose open={open} onClose={!open}>
      <DialogContent sx={{ '&.MuiDialogContent-root': { pt: 1 } }}>
        {parse(LegalNoticeContent)}
        <FormControlLabel
          control={
            <Checkbox
              checked={isAccepted}
              onChange={(_, value) => setIsAccepted(value)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={t('accept-legal-notice')}
        />
        <Box display="flex" justifyContent="center">
          <Button
            sx={{
              mt: 1,
              width: (theme) => theme.spacing(20),
              display: 'flex',
              justifyContent: 'center',
            }}
            variant="contained"
            autoFocus
            type="submit"
            disabled={!isAccepted}
            onClick={acceptLegalNotice}
          >
            {t('confirm')}
          </Button>
        </Box>
      </DialogContent>
    </DialogEscapeClose>
  )
}
export default LegalNoticeAcceptanceDialog
