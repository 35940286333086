import React from 'react'
import { useLocation } from 'react-router-dom'
import LoadingAdminLayout from '../LoadingAdminLayout'
import LoadingStandardLayout from '../LoadingStandardLayout'
import { ADMIN_PATH_STARTS_WITH } from './enums'

function SuspensedRoute({ children, fallback = null }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  const { pathname } = useLocation()
  const isAdminPage = pathname.startsWith(ADMIN_PATH_STARTS_WITH)
  return (
    <React.Suspense
      fallback={
        fallback || isAdminPage ? (
          <LoadingAdminLayout />
        ) : (
          <LoadingStandardLayout />
        )
      }
    >
      {children}
    </React.Suspense>
  )
}

export default SuspensedRoute
