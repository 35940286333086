export const HOME_PATH = '/'
export const LOGIN_PATH = '/login'
export const LOGOUT_PATH = '/logout'
export const PROFILE = '/profile'
export const RESET_PASSWORD_PATH = '/reset-password/:key'
export const FORGOT_PASSWORD_PATH = '/forgot-password'
export const FORGOT_PASSWORD_SUCCESS_PATH = '/forgot-password/success'
export const RESERVATIONS_CREATE_PATH = '/reservations/create'
export const RESERVATIONS_EDIT_PATH = '/reservations/:id/edit'
export const PRIVACY_POLICY_PATH = '/privacy-policy'

export const ADMIN_PATH = '/admin'
export const EMAIL_MANAGEMENT_PATH = '/admin/email-management'
export const EMAIL_MANAGEMENT_TEMPLATES_PATH =
  '/admin/email-management/templates'
export const EMAIL_MANAGEMENT_TEMPLATES_CREATE_PATH =
  '/admin/email-management/templates/create'
export const EMAIL_MANAGEMENT_TEMPLATES_EDIT_PATH =
  '/admin/email-management/templates/:id/edit'
export const EMAIL_MANAGEMENT_LAYOUT_PATH = '/admin/email-management/layout'
export const EMAIL_MANAGEMENT_FUSION_FIELDS_PATH =
  '/admin/email-management/fusion-fields'
export const EMAIL_MANAGEMENT_FUSION_FIELDS_CREATE_PATH =
  '/admin/email-management/fusion-fields/create'
export const EMAIL_MANAGEMENT_TEST_PATH = '/admin/email-management/test'
export const INTERNATIONALIZATION_PATH = '/admin/internationalization'
export const ADMIN_PRIVACY_POLICY_PATH = '/admin/privacy-policies'
export const USERS_PATH = '/admin/users'
export const SEED_SELLERS_PATH = '/admin/seed-sellers'
export const SEEDS_PATH = '/admin/seeds'
export const SUGAR_FACTORIES_PATH = '/admin/sugar-factories'
export const PRODUCTS_PATH = '/admin/products'
export const DISTRIBUTORS_PATH = '/admin/distributors'
export const APP_PARAMS_PATH = '/admin/app-params'
export const LOGS_PATH = '/admin/logs'
export const ROLES_PATH = '/admin/roles'
export const PERMISSIONS_PATH = '/admin/permissions'
export const LEGAL_NOTICES_PATH = '/admin/legal-notices'
