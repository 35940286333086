export enum DefaultRole {
  SUPERVISOR = 'SUPERVISOR',
  ADMIN_BAYER = 'ADMIN_BAYER',
  COMMERCIAL_BAYER = 'COMMERCIAL_BAYER',
  SUGAR_FACTORY_ADMIN = 'SUGAR_FACTORY_ADMIN',
  SUGAR_FACTORY_USER = 'SUGAR_FACTORY_USER',
  DISTRIBUTOR = 'DISTRIBUTOR',
  SEED_SELLER = 'SEED_SELLER',
}

export enum RoleType {
  SUGAR_FACTORY = 10,
  DISTRIBUTOR = 20,
  SEED_SELLER = 30,
}
