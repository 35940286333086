import React from 'react'
import LoadingScreen from 'components/atoms/LoadingScreen'
import AdminLayout from '../AdminLayout'

function LoadingAdminLayout() {
  return (
    <AdminLayout>
      <LoadingScreen />
    </AdminLayout>
  )
}

export default LoadingAdminLayout
