import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import {
  APP_PARAM,
  APP_PARAMS,
  APP_PARAMS_AUTHORIEZD_TO_READ,
} from './templates'
import { AppParam } from './types'

export function useAppParamsQuery(
  params?,
  options?,
  filtersPaginationAndSort?,
) {
  return useQueryWrapper<PaginatedType<AppParam>>(
    'GET',
    APP_PARAMS,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useAppParamQuery(params?, options?) {
  return useQueryWrapper<AppParam>('GET', APP_PARAM, params, options)
}

export function useAppParamAuthorizedToReadQuery(params?, options?) {
  return useQueryWrapper<AppParam>(
    'GET',
    APP_PARAMS_AUTHORIEZD_TO_READ,
    params,
    options,
  )
}
