import { Logout } from '@mui/icons-material'
import { Box, Tooltip, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Logo from 'assets/images/mini-logo.png'
import ImpersonateManager from 'components/organisms/ImpersonateManager'
import { DIVIDER, LINK, useGetMenuItems } from 'config/drawers/admin'
import { useAuth } from 'contexts/AuthContext'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

function MenuItems({ setOpen, open }) {
  const { pathname } = useLocation()
  const menuItems = useGetMenuItems()
  const { logout } = useAuth()
  const { t } = useTranslation()
  const handleSetOpen = () => {
    setOpen(!open)
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 1,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          flex: 1,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, pb: 0.5 }}>
          <Box
            onClick={handleSetOpen}
            sx={{
              height: (theme) => theme.spacing(7),
              width: (theme) => theme.spacing(7),
              '& img': {
                width: '100%',
              },
              cursor: 'pointer',
            }}
          >
            <img src={Logo} alt="logo-convisio" />
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            height: 1,
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
            pb: 1,
          }}
        >
          <List>
            {menuItems.map(({ key, type, title, icon: Icon, to = '#' }) => {
              const selected = pathname.startsWith(to)
              switch (type) {
                case LINK:
                  return (
                    <ListItem key={key} disablePadding sx={{ width: '100%' }}>
                      <ListItemButton
                        component={Link}
                        to={to}
                        selected={selected}
                        sx={{
                          display: 'flex',
                          justifyContent: open ? 'flex-start' : 'center',
                          px: open ? 2 : 0,
                          '& .MuiTouchRipple-root': {
                            display: 'none',
                          },
                          width: '100%',
                        }}
                      >
                        {Icon && (
                          <Tooltip title={title} followCursor>
                            <Box
                              sx={{
                                p: 2.5,
                                bgcolor: selected
                                  ? 'secondary.main'
                                  : 'common.white',
                                borderRadius: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: (theme) => theme.spacing(4),
                                width: (theme) => theme.spacing(4),
                              }}
                            >
                              <Icon
                                sx={{
                                  color: selected
                                    ? 'common.white'
                                    : 'primary.main',
                                }}
                              />
                            </Box>
                          </Tooltip>
                        )}
                        {open && title && (
                          <Typography sx={{ ml: 1, color: 'common.white' }}>
                            {title}
                          </Typography>
                        )}
                      </ListItemButton>
                    </ListItem>
                  )

                case DIVIDER:
                  return <Divider key={key} />

                default:
                  return null
              }
            })}
          </List>
        </Box>
      </Box>
      <Box sx={{ width: 1 }}>
        <Divider key="member-area" />
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ImpersonateManager open={open} />
          <Tooltip title={t('logout')} followCursor>
            <Box sx={{ px: 2, width: 1 }}>
              <Box
                sx={{
                  bgcolor: 'common.white',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 1,
                  borderRadius: 1,
                  my: 1,
                  width: 1,
                  '&:hover': {
                    bgcolor: 'grey.100',
                  },
                }}
                onClick={logout}
              >
                <Logout />
                {open && (
                  <Typography sx={{ ml: 1, color: 'common.black' }}>
                    {t('logout')}
                  </Typography>
                )}
              </Box>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  )
}

export default MenuItems
