import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import DialogEscapeClose from 'components/templates/DialogEscapeClose'
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

const ConfirmContext = createContext(null)

function ConfirmProvider({ children }) {
  const { t } = useTranslation()
  const [resolveReject, setResolveReject] = useState([])
  const [resolve, reject] = resolveReject
  const [dialog, setDialog] = useState({ title: '', description: '' })
  const { title, description } = dialog

  const confirm = useCallback((_title = '', _description = '') => {
    return new Promise((_resolve, _reject) => {
      setDialog({ title: _title, description: _description })
      setResolveReject([_resolve, _reject])
    })
  }, [])

  const handleClose = useCallback(() => {
    setResolveReject([])
  }, [])

  const handleCancel = useCallback(() => {
    if (reject) {
      reject()
      handleClose()
    }
  }, [reject, handleClose])

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve()
      handleClose()
    }
  }, [resolve, handleClose])

  const values = useMemo(
    () => ({
      confirm,
    }),
    [confirm],
  )

  return (
    <>
      <ConfirmContext.Provider value={values}>
        {children}
      </ConfirmContext.Provider>
      <DialogEscapeClose
        onClose={handleCancel}
        fullWidth
        open={resolveReject.length === 2}
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        {description && (
          <DialogContent>
            <DialogContentText>{description}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleCancel}>{t('cancel')}</Button>
          <Button color="primary" variant="contained" onClick={handleConfirm}>
            {t('confirm')}
          </Button>
        </DialogActions>
      </DialogEscapeClose>
    </>
  )
}

const useConfirm = () => {
  const context = useContext(ConfirmContext)
  if (context === undefined) {
    throw new Error('useConfirm must be used within a ConfirmProvider')
  }
  return context
}

export { ConfirmProvider, useConfirm }
