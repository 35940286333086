import Box from '@mui/material/Box'
import { useAuth } from 'contexts/AuthContext'
import React, { useEffect } from 'react'
import { useLastLegalNoticesQuery } from 'domains/legalNotices/queries'
import LegalNoticeAcceptanceDialog from './components'

function LegalNoticeAcceptanceLayout({ children }) {
  const [open, setOpen] = React.useState(false)
  const { user } = useAuth()
  const { data: LegalNotice } = useLastLegalNoticesQuery(null, {
    enabled: !!user,
  })

  useEffect(() => {
    if (!user || !LegalNotice) setOpen(false)
    else
      setOpen(
        !user.lastAcceptedLegalNoticeDate ||
          user.lastAcceptedLegalNoticeDate < LegalNotice.applicationDate,
      )
  }, [user, LegalNotice])

  return (
    <Box>
      {LegalNotice && (
        <LegalNoticeAcceptanceDialog
          open={open}
          LegalNoticeContent={LegalNotice.description}
          LegalNoticeId={LegalNotice.id}
        />
      )}
      {children}
    </Box>
  )
}

export default LegalNoticeAcceptanceLayout
