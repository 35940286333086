import { Button, DialogActions, DialogTitle } from '@mui/material'
import DialogEscapeClose from 'components/templates/DialogEscapeClose'
import React from 'react'
import { useTranslation } from 'react-i18next'

function ImpersonateDialogConfirm({ onClose, onConfirm, open }) {
  const { t } = useTranslation()

  return (
    <DialogEscapeClose onClose={onClose} open={open}>
      <DialogTitle> {t('impersonate-disconnect')} </DialogTitle>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <Button onClick={onConfirm}>{t('confirm')}</Button>
      </DialogActions>
    </DialogEscapeClose>
  )
}

export default ImpersonateDialogConfirm
