import StandardLayout from 'components/templates/StandardLayout'
import SuspensedRoute from 'components/templates/SuspensedRoute'
import * as React from 'react'
import { Outlet } from 'react-router-dom'

function App() {
  return (
    <SuspensedRoute>
      <StandardLayout>
        <Outlet />
      </StandardLayout>
    </SuspensedRoute>
  )
}

export default App
