import { PaletteColorOptions, PaletteOptions } from '@mui/material'

export const green = '#267150'
export const lemon = '#89d329'
export const lemonLight = '#cae7a3'
export const orange = '#ff6d00'
export const orangeLight = '#f9bf93'
export const red = '#ff0000'
export const beige = '#f3efe9'
export const black = '#242423'
export const yellow = '#ffb800'
export const grey70 = '#4d4d4d'
export const grey60 = '#666666'
export const grey50 = '#808080'
export const grey40 = '#a6a6a6'
export const grey30 = '#b3b3b3'
export const grey20 = '#cccccc'
export const grey10 = '#e6e6e6'
export const white = '#ffffff'

const palette: PaletteOptions = {
  primary: {
    main: green,
    contrastText: beige,
  },
  secondary: {
    main: orange,
    light: orangeLight,
  },
  tertiary: {
    main: lemon,
    light: lemonLight,
  },
  info: {
    main: yellow,
  },
  error: {
    main: orange,
  },
  success: {
    main: lemon,
  },
  text: {
    primary: black,
  },
}

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: PaletteColorOptions
  }
  interface PaletteOptions {
    tertiary: PaletteColorOptions
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    myAwesomeColor: true
  }
}

export default palette

// https://mui.com/material-ui/customization/palette/
